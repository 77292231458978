<script setup lang="ts">
import type { StateModal } from '~/types/types'

const { t } = useI18n()
const { createNotification } = useNotifications()
const { houseIdsAlreadyInFavorites, deleteHouseWishlist } =
  useMultipleWishlist()

const stateModal = useState<StateModal>('state-modal')

const props = defineProps<{
  houseId: number
  houseName: string
}>()
const emits = defineEmits<{
  'callback-click': [houseId: number]
}>()
defineSlots<{ default(p: { active: boolean }): any }>()

const isLinked = computed(() =>
  houseIdsAlreadyInFavorites.value.includes(props.houseId),
)

const handleWishlistClick = () => {
  if (houseIdsAlreadyInFavorites.value.includes(props.houseId)) {
    removeHouseWishlist()
  } else {
    openModal()
  }
}

const openModal = () => {
  emits('callback-click', props.houseId)
  stateModal.value.multipleWishlistIsOpen = true
}

const removeHouseWishlist = async () => {
  try {
    await deleteHouseWishlist(props.houseId)

    createNotification({
      message: t('wishlistMultiple.houseDeleted', {
        houseName: props.houseName,
      }),
      showCloseButton: false,
      type: 'secondary',
    })
  } catch (err) {
    console.log('err', err)
  }
}
</script>

<template>
  <div data-cy="favorite-button" @click.stop.prevent="handleWishlistClick">
    <slot :active="isLinked">
      <BaseFavoriteButton :is-active="isLinked" />
    </slot>
  </div>
</template>
